<template>
  <v-container fluid fill-height>
    <v-row v-for="user in students" :key="user.id"><v-col>
	<v-card>
	  <v-card-title >{{ user.firstName }} {{ user.lastName }}</v-card-title>
	  <v-card-subtitle v-for="checkin in mostRecentCheckin(user.id)" :key="'s-' + checkin.id">
by {{ users[checkin.counselor].firstName }} {{ users[checkin.counselor].lastName }} at {{ checkin.createdAt | moment('MMMM Do YYYY, h:mma') }},
	  {{ checkin.createdAt | moment("from", "now") }}.
	  </v-card-subtitle>
	  <v-card-text v-if="!isCheckedIn(user.id)">
	    <strong>Not checked in.</strong>
	  </v-card-text>
	  <v-card-text v-for="checkin in mostRecentCheckin(user.id)" :key="'t-' + checkin.id">
	    {{ checkin.notes }}
	  </v-card-text>
    </v-card></v-col></v-row>
</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['userCheckins', 'checkins', 'users']),
    students() {
      return Object.values(this.users).filter(u => (!u.isStaff));
    },
  },
  data() {
    return {
    };
  },

  methods: {
    ...mapActions([
      'getCheckins',
      'getUsers',
    ]),
    mostRecentCheckin(u) {
      let result;
      if (this.userCheckins[u]) {
	for (const c of Object.values(this.userCheckins[u])) {
	  if (result === undefined) {
	    result = c;
	  } else if ((new Date(result.createdAt)).getTime() < (new Date(c.createdAt)).getTime()) {
	    result = c;
	  }
	}
      }
      if (result === undefined) {
	return [];
      }
      return [result];
    },
    isCheckedIn(u) {
      const r = this.mostRecentCheckin(u);
      if (r.length === 0) return false;
      const d = new Date(r[0].createdAt);
      const duration = (new Date()).getTime() - d.getTime();
      return (duration < 4e7);
    },
  },

  mounted() {
    this.getUsers();
    return this.getCheckins();
  },

};
</script>
