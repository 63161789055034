<template>
  <v-container fluid fill-height>
    <v-row><v-col>
	<v-card>
	<v-card-title>{{ users[user].firstName }} {{ users[user].lastName }}</v-card-title>
	<v-card-subtitle>Check in</v-card-subtitle>
	<v-card-text>
	    <v-layout wrap>
	      <v-flex xs12>
		<v-textarea
		  outlined
		  label="Your notes"
                  v-model="text"
		  >
		</v-textarea>
              </v-flex>
	      </v-layout>
	</v-card-text>
    <v-card-actions>
      <v-btn
	text
	@click="submit"
	color="primary"
        :disabled="text.length == 0"
	>
	Submit
      </v-btn>
    </v-card-actions>
    </v-card></v-col></v-row>
</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['checkins', 'users']),
  },

  data() {
    return {
      user: undefined,
      text: '',
      week: 0,
      problemSet: 0,
    };
  },

  methods: {
    ...mapActions([
      'getCheckins',
      'getUsers',
      'submitCheckin',
    ]),

    submit() {
      this.submitCheckin({
	user: this.user,
	notes: this.text,
      });
      this.$router.push({ path: '/checkins/' });
    },
  },


  mounted() {
    this.getUsers();
    this.user = this.$route.params.user;
    return this.getCheckins();
  },

};
</script>
